<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column no-wrap gap-md">
        {{ message }}
        <q-checkbox
          dense
          v-model="dontShowAgain"
          :label="$t('productGroupDiscount.dontShowAgain')"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn dense flat :label="$t('Cancel')" @click="onDialogCancel" />
        <q-btn dense color="primary" :label="$t('OK')" @click="confirm" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";

const props = defineProps<{
  message: string;
  confirmationId: string;
}>();

defineEmits(useDialogPluginComponent.emits);

const dontShowAgain = ref(false);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

function confirm() {
  if (dontShowAgain.value) {
    localStorage.setItem(props.confirmationId, "true");
  }
  onDialogOK();
}
</script>
