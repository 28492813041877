import OverwritePositionsConfirmationDialog from "@/components/InquiryPage/QuotationValuesDisplay/OverwritePositionsConfirmationDialog.vue";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { Dialog } from "quasar";

export async function confirmDiscountOverwrite(
  message: string,
  confirmationId: string
): Promise<boolean> {
  const { organization } = storeToRefs(useCurrentOrganizationStore());
  if (!organization.value?.usePositionDiscounts) {
    return true;
  }

  const dontShowAgain = localStorage.getItem(confirmationId);
  if (dontShowAgain) {
    return true;
  }
  return new Promise((resolve) => {
    Dialog.create({
      component: OverwritePositionsConfirmationDialog,
      componentProps: {
        message,
        confirmationId,
      },
    })
      .onOk(() => resolve(true))
      .onCancel(() => resolve(false));
  });
}
