export default {
  "Organization settings": "Organisationseinstellungen",
  "You cannot change your own role":
    "Sie können Ihre eigene Rolle nicht ändern",
  "Are you sure you want to remove this user?":
    "Möchten Sie diesen Benutzer wirklich entfernen?",
  "This field must not be empty.": "Dieses Feld darf nicht leer sein.",
  "Enter new password": "Neues Passwort eingeben",
  "Remove user": "Benutzer entfernen",
  "No inboxes found for this organization. Please create one.":
    "Für diese Organisation wurden keine Eingangskörbe gefunden. Bitte erstellen Sie einen.",
  Description: "Beschreibung",
  Emails: "E-Mails",
  "Invite users": "Mitglieder einladen",
  "Please enter the email addresses of users to invite, separated by newline.":
    "Bitte geben Sie die E-Mail-Adressen der einzuladenden Benutzer ein, getrennt durch einen Zeilenumbruch.",
  Invite: "Einladen",
  Active: "Aktiv",
  Invited: "Eingeladen",
  "Shown fields in overview table":
    "Angezeigte Felder in der Übersichtstabelle",
  "# items": "# Elemente",
  "Extracted fields": "Extrahierte Felder",
  "Members with access": "Mitglieder mit Zugang",
  "Create new inbox": "Neuen Dokumenten-Ordner erstellen",
  "Edit inbox": "Dokumenten-Ordner bearbeiten",
  "Delete inbox": "Ordner löschen",
  "Name is required": "Name ist erforderlich",
  "Short code is required": "Kürzel ist erforderlich",
  "Short code has to be between 1 and 5 symbols":
    "Kürzel muss zwischen 1 und 5 Zeichen lang sein",
  "Short code has to contain only letters or numbers":
    "Kürzel darf nur Buchstaben oder Zahlen enthalten",
  "An inbox with such code already exists. Please, choose a different code":
    "Bitte ein anderes Kürzel wählen, diese ist schon belegt",
  "Label is required": "Name ist erforderlich",
  "Color is required": "Farbe ist erforderlich",
  Layout: "Layout",
  Users: "Benutzer",
  Inboxes: "Dokumenten-Ordner",
  "Invite to inboxes": "Zu Dokumenten-Ordnern einladen",
  "Organization updated": "Organisation aktualisiert",
  OK: "OK",
  "Are you sure you want to delete this inbox?":
    "Möchten Sie diesen Dokumenten-Ordner wirklich löschen?",
};
