<template>
  <div class="row no-wrap items-baseline gap-sm multi-email-input">
    <div class="col-auto">
      {{ label }}
    </div>
    <q-select
      ref="selectEl"
      dense
      borderless
      multiple
      hide-dropdown-icon
      input-debounce="0"
      use-input
      :model-value="recipients"
      :disable="disable"
      :options="options"
      new-value-mode="add-unique"
      @new-value="handleNewValue"
      @update:model-value="handleSelect"
      @keydown.backspace.exact.stop="handleBackspace"
      @filter="handleFilter"
    >
      <template #selected-item="scope">
        <q-chip
          dense
          removable
          square
          :color="scope.opt.supplierContactId ? 'neutral-2' : 'neutral-2'"
          @remove="scope.removeAtIndex(scope.index)"
          :tabindex="scope.tabindex"
          class="q-ml-xs cursor-pointer"
          @click.stop.prevent
          @mousedown.stop.prevent
          @touchstart.stop.prevent
          @keydown.stop.prevent
        >
          <template v-if="scope.opt.displayName">
            <div>{{ scope.opt.displayName }}</div>
            <q-tooltip>{{ scope.opt.address }}</q-tooltip>
          </template>
          <div v-else>{{ scope.opt.address }}</div>
          <q-menu :offset="[0, 4]">
            <q-card>
              <q-card-section>
                <div class="row no-wrap items-start gap-md">
                  <div>
                    <q-icon name="sym_r_person" size="md" color="neutral-5" />
                  </div>
                  <div class="col">
                    <div class="text-body1 q-mb-xs">
                      {{ scope.opt.displayName ?? scope.opt.address }}
                    </div>
                    <div class="text-sm text-neutral-7">
                      {{ scope.opt.address }}
                    </div>
                    <q-btn
                      v-if="
                        scope.opt.supplierContactId == null &&
                        scope.opt.userId == null
                      "
                      dense
                      outline
                      icon="sym_r_add"
                      flat
                      class="q-mt-md"
                      v-close-popup
                      @click="addToSupplierContacts(scope.opt.address)"
                    >
                      {{ $t("supplierRfqsPage.addToSupplierContacts") }}
                    </q-btn>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </q-menu>
        </q-chip>
      </template>
      <template #option="scope">
        <q-item v-bind="scope.itemProps">
          <q-item-section>
            <template v-if="scope.opt.displayName">
              <q-item-label>
                {{ scope.opt.displayName }}
              </q-item-label>
              <q-item-label caption>
                {{ scope.opt.address }}
              </q-item-label>
            </template>
            <template v-else>
              <q-item-label>
                {{ scope.opt.address }}
              </q-item-label>
            </template>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script setup lang="ts">
import type { SupplierContact } from "@/types/supplier";
import type { SupplierRfqRecipient } from "@/types/supplierRfq";
import { getDisplayName } from "@/utils/displayName";
import { useQuasar, type QSelect } from "quasar";
import { ref } from "vue";
import AddToSupplierContactsDialog from "./AddToSupplierContactsDialog.vue";

const MAX_OPTIONS_TO_SHOW = 5;

const props = defineProps<{
  supplierId: number;
  recipients: SupplierRfqRecipient[];
  label: string;
  disable?: boolean;
  contacts: SupplierRfqRecipient[];
}>();

const emit = defineEmits<{
  "update:emails": [emails: SupplierRfqRecipient[]];
}>();

const selectEl = ref<QSelect | null>(null);

const options = ref<SupplierRfqRecipient[]>(props.contacts);

function handleSelect(emails: SupplierRfqRecipient[]) {
  emit("update:emails", emails);
}

function handleNewValue(
  emailAddress: string,
  done: (
    email?: SupplierRfqRecipient,
    mode?: "add-unique" | "add" | "toggle"
  ) => void
) {
  if (props.recipients.find((email) => email.address === emailAddress)) {
    // has already been added as a recipient
    done();
    return;
  }
  const email = {
    address: emailAddress,
    supplierContactId: null,
    userId: null,
  };
  done(email, "add-unique");
}

function handleBackspace() {
  if (!props.recipients.length) return;
  if (getTextCursorPosition() !== 0) return;
  emit("update:emails", props.recipients.slice(0, -1));
}

function getTextCursorPosition() {
  if (!selectEl.value) return -1;
  const inputEl = selectEl.value.$el.querySelector("input");
  if (!inputEl) return -1;
  return inputEl.selectionStart;
}

type voidFn = () => void;
type FilterUpdate = (callbackFn: voidFn, afterFn?: voidFn) => void;

function handleFilter(val: string, update: FilterUpdate) {
  update(() => {
    const needle = val.toLowerCase();
    options.value = props.contacts
      .filter(
        (v) =>
          `${v.displayName} ${v.address}`.toLowerCase().indexOf(needle) > -1
      )
      .slice(0, MAX_OPTIONS_TO_SHOW);
  });
}

const q = useQuasar();
function addToSupplierContacts(email: string) {
  q.dialog({
    component: AddToSupplierContactsDialog,
    componentProps: {
      initialEmail: email,
      supplierId: props.supplierId,
    },
  }).onOk((data) => {
    updateRecipient(email, data);
  });
}

function updateRecipient(email: string, data: SupplierContact) {
  const index = props.recipients.findIndex(
    (recipient) => recipient.address === email
  );
  if (index === -1) return;
  const newRecipients = [...props.recipients];
  newRecipients[index] = {
    ...newRecipients[index],
    address: data.address,
    displayName: getDisplayName(data),
    supplierContactId: data.id,
  };
  emit("update:emails", newRecipients);
}
</script>

<style scoped lang="scss">
.multi-email-input {
  min-height: 42px;
}

.add-to-supplier-contacts-button {
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
</style>
