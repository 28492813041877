<template>
  <div class="column no-wrap gap-y-md">
    <inquiry-values-display-card caption="inquiryPage.inquiryBaseData.header">
      <basic-inquiry-values />
    </inquiry-values-display-card>
    <div class="row no-wrap gap-x-md justify-stretch full-width">
      <inquiry-values-display-card
        class="col overflow-hidden"
        caption="inquiryPage.progress.header"
      >
        <div class="column gap-y-md items-start">
          <progress-display v-if="inquiry" :progress="inquiry.progress" />
          <q-skeleton v-else style="height: 106px; width: 100%" />
          <q-btn
            dense
            icon="sym_r_list_alt"
            :label="$t('inquiryPage.progress.edit')"
            color="primary"
            :to="{ name: 'inquiry-positions' }"
            class="no-decoration"
          />
        </div>
      </inquiry-values-display-card>
      <inquiry-values-display-card
        class="col overflow-hidden"
        caption="inquiryPage.manufacturerTypes.header"
        v-if="inquiry && organization?.showManufacturerTypes !== false"
      >
        <div>
          <manufacturer-types-display
            v-if="inquiry"
            class="full-width q-mt-sm q-mb-lg"
            :progress="inquiry.progress"
          />
          <q-skeleton style="height: 64px" v-else />
        </div>
      </inquiry-values-display-card>
    </div>
    <inquiry-values-display-card caption="inquiryPage.files.header">
      <q-card flat bordered v-if="inquiry">
        <files-list />
      </q-card>
      <a
        :class="{
          block: true,
          'q-mt-sm': true,
          'cursor-pointer': true,
          disabled: inquiry?.status.originalStatus !== 'REVIEWING',
        }"
        @click="
          if (inquiry?.status.originalStatus === 'REVIEWING') chooseBOQs();
        "
      >
        {{ $t("selectBOQsDialog.buttonLabel") }}
        <q-tooltip v-if="inquiry?.status.originalStatus !== 'REVIEWING'">
          {{ $t("selectBOQsDialog.disabledTooltip") }}
        </q-tooltip>
      </a>
    </inquiry-values-display-card>
  </div>
</template>

<script setup lang="ts">
import SelectBOQsDialog from "@/components/InquiryPage/InquiryValuesDisplay/SelectBOQsDialog.vue";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import InquiryValuesDisplayCard from "../InquiryValuesDisplayCard.vue";
import BasicInquiryValues from "./BasicInquiryValues.vue";
import FilesList from "./FilesList.vue";
import ManufacturerTypesDisplay from "./ManufacturerTypesDisplay.vue";
import ProgressDisplay from "./ProgressDisplay.vue";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const q = useQuasar();

function chooseBOQs() {
  q.dialog({
    component: SelectBOQsDialog,
    componentProps: {
      inquiry: inquiry.value,
    },
  });
}
</script>

<style scoped lang="scss">
.inquiry-value-card {
  border: 1px solid rgba(0, 0, 0, 0.24);
}
</style>
