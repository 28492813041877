import type { Product, Unit } from "@/types/product";

export function getAmountAndUnitForNewOfferPosition(
  boqAmount: number | null,
  boqUnit: Unit | null,
  product: Product
): [amount: number | null, unit: Unit] {
  const unit = getBestProductUnit(product, boqUnit);
  const amount = getAmountForNewOfferPosition(
    boqAmount,
    boqUnit,
    unit,
    product
  );
  return [amount, unit];
}

function getBestProductUnit(product: Product, boqUnit: Unit | null): Unit {
  if (boqUnit && product.alternativeUnits.includes(boqUnit)) return boqUnit;
  return product.unit;
}

function getAmountForNewOfferPosition(
  boqAmount: number | null,
  boqUnit: Unit | null,
  unit: Unit,
  product: Product
): number | null {
  if (!boqAmount || !boqUnit) return null;

  if (unit === "LUMPSUM") {
    // "PAUSCHAL" - always has amount 1
    return 1;
  }

  return convertAmount(boqAmount, boqUnit, unit, product);
}

export function convertAmount(
  amount: number,
  from_unit: Unit,
  to_unit: Unit,
  product: Product
): number | null {
  if (from_unit === to_unit) {
    return amount;
  }

  const baseAmount = convertToBaseAmount(amount, from_unit, product);
  if (!baseAmount || to_unit === product.unit) {
    return baseAmount;
  }

  const convFromBase = product.conversions[to_unit.toLowerCase()];
  if (!convFromBase) {
    return null;
  }
  return (baseAmount * convFromBase.denominator) / convFromBase.numerator;
}

function convertToBaseAmount(
  amount: number,
  unit: Unit,
  product: Product
): number | null {
  if (unit === product.unit) {
    return amount;
  }

  const convToBase = product.conversions[unit.toLowerCase()];
  if (!convToBase) {
    return null;
  }
  return (amount * convToBase.numerator) / convToBase.denominator;
}
