<template>
  <q-skeleton v-if="isLoading" />
  <q-item class="q-pl-none">
    <q-item-section>
      <q-select
        class="q-small-input"
        dense
        outlined
        hide-bottom-space
        autofocus
        v-model="newDiscountProductGroup"
        :placeholder="t('productGroupDiscount.addDiscount.label')"
        :options="notYetAddedProductGroups"
        :option-label="(productGroup) => productGroup.name"
      />
    </q-item-section>
    <q-item-section side>
      <q-input
        class="discount-input q-small-input"
        dense
        outlined
        hide-bottom-space
        v-model="newDiscountDiscount"
        type="number"
        step="0.01"
        suffix="%"
        input-class="text-right"
        @keydown.enter="confirmAddDiscount()"
      />
    </q-item-section>
    <q-item-section side>
      <div class="row no-wrap items-center gap-xs">
        <q-btn
          flat
          dense
          size="sm"
          icon="sym_r_close"
          @click="$emit('cancel')"
        />
        <q-btn
          flat
          dense
          size="sm"
          icon="sym_r_check"
          :disable="!newDiscountProductGroup || !newDiscountDiscount"
          :loading="isAddingDiscount"
          @click="confirmAddDiscount()"
        />
      </div>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type {
  NoProductGroup,
  ProductGroup,
  ProductGroupOrNoGroup,
} from "@/types/productGroup";
import type { ProductGroupDiscount } from "@/types/productGroupDiscount";
import { confirmDiscountOverwrite } from "@/utils/discountOverwriteConfirmation";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

defineProps<{
  discounts: ProductGroupDiscount[];
  notYetAddedProductGroups: ProductGroupOrNoGroup[];
  noProductGroup: NoProductGroup;
  isAddingDiscount: boolean;
  showSeparator: boolean;
  isLoading: boolean;
}>();

const emit = defineEmits<{
  add: [productGroup: ProductGroupOrNoGroup, discount: number];
  cancel: [];
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const newDiscountProductGroup = ref<ProductGroup | null>(null);
const newDiscountDiscount = ref<number | null>(null);

const { t } = useI18n();

async function confirmAddDiscount() {
  if (!newDiscountProductGroup.value || !newDiscountDiscount.value) {
    return;
  }
  if (!organization.value?.usePositionDiscounts) {
    addDiscount();
    return;
  }
  const isConfirmed = await confirmDiscountOverwrite(
    t("productGroupDiscount.addDiscount.confirmMessage"),
    "addDiscount"
  );
  if (isConfirmed) addDiscount();
}

function addDiscount() {
  if (!newDiscountProductGroup.value || !newDiscountDiscount.value) {
    return;
  }
  emit("add", newDiscountProductGroup.value, newDiscountDiscount.value);
  clearNewDiscount();
}

function clearNewDiscount() {
  newDiscountProductGroup.value = null;
  newDiscountDiscount.value = null;
}
</script>

<style scoped lang="scss">
.discount-input {
  width: 100px;
}
</style>
