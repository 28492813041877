<template>
  <q-btn :icon="icons.filter" :label="$t('Filter')" class="font-weight-inherit">
    <q-menu no-route-dismiss :offset="[0, 2]">
      <filter-menu-item
        :icon="icons.inbox"
        :label="$t('inbox.label')"
        :active="Boolean(filterOptions.inboxId?.length)"
      >
        <filter-inbox-select
          :selected-inbox-ids="filterOptions.inboxId ?? []"
          @update:selected-inbox-ids="handleInboxIdsFilterChange($event)"
        />
      </filter-menu-item>
      <filter-menu-item
        :icon="icons.inquiry.shortCode"
        :label="$t('inquiry.shortCode')"
        :active="Boolean(filterOptions.inquiryShortCode?.length)"
      >
        <text-filter
          :model-value="filterOptions.inquiryShortCode?.join(',') ?? null"
          @update:model-value="
            handleStringFilterChange('inquiryShortCode', $event)
          "
          :placeholder="$t('inquiry.shortCode')"
        />
      </filter-menu-item>
      <filter-menu-item
        :icon="icons.customer"
        :label="$t('quotation.customer')"
        :active="Boolean(filterOptions.customer?.length)"
      >
        <text-filter
          :model-value="filterOptions.customer?.join(',') ?? null"
          @update:model-value="handleStringFilterChange('customer', $event)"
          :placeholder="$t('quotation.customer')"
        />
      </filter-menu-item>
      <filter-menu-item
        :icon="icons.inquiry.buildingProject"
        :label="$t('inquiry.buildingProject')"
        :active="Boolean(filterOptions.buildingProject?.length)"
      >
        <text-filter
          :model-value="filterOptions.buildingProject?.join(',') ?? null"
          @update:model-value="
            handleStringFilterChange('buildingProject', $event)
          "
          :placeholder="$t('inquiry.buildingProject')"
        />
      </filter-menu-item>
      <filter-menu-item
        :icon="icons.quotation.status"
        :label="$t('quotation.status')"
        :active="Boolean(filterOptions.status?.length)"
      >
        <filter-status-select
          :selected-statuses="filterOptions.status ?? []"
          @update:selected-statuses="handleListFilterChange('status', $event)"
        />
      </filter-menu-item>
      <filter-menu-item
        :icon="icons.user.main"
        :label="$t('quotation.assignedUser')"
        :active="Boolean(filterOptions.assignedUserId?.length)"
      >
        <filter-users-select
          :selected-user-ids="filterOptions.assignedUserId ?? []"
          @update:selected-user-ids="handleUserIdsFilterChange($event)"
        />
      </filter-menu-item>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import type { ListQuotationFilterOptions } from "@/api/quotation";
import FilterMenuItem from "@/components/FilterMenu/FilterMenuItem.vue";
import TextFilter from "@/components/FilterMenu/TextFilter.vue";
import icons from "@/config/icons";
import { useCurrentQuotationsStore } from "@/stores/currentQuotations";
import { storeToRefs } from "pinia";
import FilterInboxSelect from "./FilterInboxSelect.vue";
import FilterStatusSelect from "./FilterStatusSelect.vue";
import FilterUsersSelect from "./FilterUsersSelect.vue";
const store = useCurrentQuotationsStore();
const { filterOptions } = storeToRefs(store);
const { addFilter, removeFilter } = store;

const handleStringFilterChange = (
  key: keyof ListQuotationFilterOptions,
  value: string | null
) => {
  if (!value) removeFilter(key);
  else addFilter(key, value?.split(",").map((s) => s.trim()) ?? []);
};

const handleInboxIdsFilterChange = (value: number[]) => {
  if (value.length > 0) addFilter("inboxId", value);
  else removeFilter("inboxId");
};

const handleListFilterChange = (
  key: keyof ListQuotationFilterOptions,
  value: string[]
) => {
  if (value.length > 0) addFilter(key, value);
  else removeFilter(key);
};

const handleUserIdsFilterChange = (value: (number | null)[]) => {
  if (value.length > 0) addFilter("assignedUserId", value);
  else removeFilter("assignedUserId");
};
</script>
