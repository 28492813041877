<template>
  <div
    class="where-text text-right text-neutral-7 row no-wrap items-center justify-end"
  >
    {{ $t("automationRule.where") }}
  </div>
</template>

<style scoped lang="scss">
.where-text {
  width: 60px;
  height: 40px;
  flex-shrink: 0;
}
</style>
