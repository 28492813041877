import type {
  ApiOfferPosition,
  ApiOfferPositionSend,
  OfferPosition,
} from "@/types/offerPosition";
import { client } from "./client/client";

export async function deleteOfferPosition(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  offerPositionId: number
): Promise<void> {
  await client.delete<void>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/offer_positions/${offerPositionId}/`
  );
}

export async function updateOfferPosition(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  offerPositionId: number,
  data: Partial<OfferPosition>
): Promise<OfferPosition> {
  const payload = offerPositionToApi(data);
  const response = await client.patch<ApiOfferPosition>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/offer_positions/${offerPositionId}/`,
    payload
  );
  return offerPositionFromApi(response.data);
}

export async function addOfferPosition(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  data: Partial<OfferPosition>
): Promise<OfferPosition> {
  const payload = offerPositionToApi(data);
  const response = await client.post<ApiOfferPosition>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/offer_positions/`,
    payload
  );
  return offerPositionFromApi(response.data);
}

export async function moveOfferPositionAfterAnother(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  offerPositionId: number,
  afterId: number | null
): Promise<{ [key: number]: number }> {
  // Returns a map of the offer position IDs and their new orders
  const response = await client.post<{ [key: number]: number }>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/offer_positions/move_after/`,
    { offerPositionId, afterId }
  );
  return response.data;
}

export function offerPositionFromApi(data: ApiOfferPosition): OfferPosition {
  return {
    ...data,
    amount: data.amount === null ? null : parseFloat(data.amount),
    unitPrice: data.unitPrice === null ? null : parseFloat(data.unitPrice),
    vatTaxRate: data.vatTaxRate === null ? null : parseFloat(data.vatTaxRate),
    discountRate:
      data.discountRate === null ? null : parseFloat(data.discountRate),
  };
}

export function offerPositionToApi(
  data: Partial<OfferPosition>
): Partial<ApiOfferPositionSend> {
  const { amount, product, ...rest } = data;
  return {
    ...rest,
    amount: amount?.toFixed(2),
    unitPrice: data.unitPrice?.toFixed(2),
    vatTaxRate: data.vatTaxRate?.toFixed(2),
    productId: product?.id,
    discountRate: data.discountRate?.toFixed(2),
  };
}
