import { createI18n } from "vue-i18n";

import messages from "@/i18n/translations";
import datetimeFormats from "./datetimeFormats";
import numberFormats from "./numberFormats";

// @ts-ignore  // vue-i18n types are not up to date
export const i18n = createI18n({
  legacy: false,
  locale: "de-DE",
  globalInjection: true,
  messages,
  // @ts-ignore  // vue-i18n types are not up to date
  datetimeFormats,
  numberFormats,
});
