<template>
  <q-menu
    :offset="[0, 4]"
    @show="onShow"
    @hide="onHide"
    @keydown.stop.a
    @keydown.stop.f
    @keydown.stop.prevent.down="selectNext"
    @keydown.stop.prevent.up="selectPrevious"
    @keydown.stop.prevent.enter="handleEnter"
  >
    <q-card>
      <div class="q-px-sm">
        <q-input
          borderless
          dense
          :placeholder="props.searchPlaceholder"
          v-model="searchText"
          autofocus
          @input="debouncedSearch"
          ref="inputEl"
          @keydown.stop.left
          @keydown.stop.right
        />
      </div>
      <q-separator />
      <div class="loading-indicator-container">
        <q-linear-progress v-if="showLoading" indeterminate color="neutral-5" />
      </div>
      <div>
        <q-list dense>
          <q-item
            v-if="
              searchText &&
              searchText.length > 0 &&
              searchText.length < MIN_SEARCH_LENGTH
            "
          >
            <q-item-section class="text-italic text-neutral-7">
              <q-item-label>
                {{
                  $t("asyncMultiSelectMenu.minSearchLength", {
                    minSearchLength: MIN_SEARCH_LENGTH,
                  })
                }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <template
            v-for="(option, index) in displayedNotSelectedOptions"
            :key="option[idValue]"
          >
            <slot
              name="option"
              :option="option"
              :toggle="() => toggleOption(option)"
            >
              <multi-select-menu-item
                :isSelected="false"
                @toggle="toggleOption(option)"
                :class="{ 'bg-neutral-2': selectedIndex === index }"
              >
                <template #label>
                  <slot name="option-label" :option="option">
                    {{ labelFn(option) }}
                  </slot>
                  <slot name="option-after" :option="option" />
                </template>
              </multi-select-menu-item>
            </slot>
          </template>
          <q-separator
            v-if="selectedOptions.length + partiallySelectedOptions.length > 0"
          />
          <template
            v-for="(option, index) in displayedSelectedOptions"
            :key="option[idValue]"
          >
            <slot
              name="option"
              :option="option"
              :toggle="() => toggleOption(option)"
            >
              <multi-select-menu-item
                :isSelected="true"
                @toggle="toggleOption(option)"
                :class="{
                  'bg-neutral-2':
                    selectedIndex ===
                    index + displayedNotSelectedOptions.length,
                }"
              >
                <template #label>
                  <slot name="option-label" :option="option">
                    {{ labelFn(option) }}
                  </slot>
                  <slot name="option-after" :option="option" />
                </template>
              </multi-select-menu-item>
            </slot>
          </template>
          <template
            v-for="(option, index) in partiallySelectedOptions"
            :key="option[idValue]"
          >
            <slot
              name="option"
              :option="option"
              :toggle="() => toggleOption(option)"
            >
              <multi-select-menu-item
                :isSelected="null"
                @toggle="toggleOption(option)"
                :class="{
                  'bg-neutral-2':
                    selectedIndex ===
                    index +
                      displayedNotSelectedOptions.length +
                      displayedSelectedOptions.length,
                }"
              >
                <template #label>
                  <slot name="option-label" :option="option">
                    {{ labelFn(option) }}
                  </slot>
                  <slot name="option-after" :option="option" />
                </template>
              </multi-select-menu-item>
            </slot>
          </template>
          <q-separator v-if="$slots.after" />
          <slot name="after" />
        </q-list>
      </div>
    </q-card>
  </q-menu>
</template>

<script
  setup
  lang="ts"
  generic="IdValue extends string, Option extends { [key in IdValue]: string | number; }"
>
import { delayLoadingIndicator } from "@/composables/useLoadingIndicator";
import debounce from "debounce-promise";
import { computed, ref, watch, watchEffect, type Ref } from "vue";
import MultiSelectMenuItem from "./MultiSelectMenuItem.vue";
import { useKeyboardSelection } from "./useKeyboardSelection";

const MIN_SEARCH_LENGTH = 3;

const props = withDefaults(
  defineProps<{
    selectedOptions: Option[];
    partiallySelectedOptions: Option[];
    searchPlaceholder: string;
    idValue: IdValue;
    preloadOptions: Option[];
    findOptions: (searchText: string) => Promise<Option[]>;
    labelFn: (option: Option) => string;
    maxOptionsDisplayed?: number;
  }>(),
  { maxOptionsDisplayed: 10 }
);

const emit = defineEmits<{
  select: [option: Option];
  unselect: [option: Option];
}>();

const inputEl = ref<HTMLInputElement | null>(null);

const searchText = ref<string | null>(null);

// The "as Ref<Option[]>" is a workaround for https://github.com/vuejs/core/issues/2136
const options = ref<Option[]>([]) as Ref<Option[]>;
const notSelectedOptionsInList = ref<Option[]>([]) as Ref<Option[]>;
const isLoading = ref(false);
const showLoading = delayLoadingIndicator(isLoading);
const isOpen = ref(false);

const displayedNotSelectedOptions = computed(() => {
  if (!searchText.value) {
    return props.preloadOptions
      .filter((option) => !isSelectedOption(option))
      .slice(0, props.maxOptionsDisplayed);
  }
  if (searchText.value.length < MIN_SEARCH_LENGTH) {
    return [];
  }
  return notSelectedOptionsInList.value.slice(0, props.maxOptionsDisplayed);
});

const displayedSelectedOptions = computed(() => {
  if (!searchText.value) {
    return props.selectedOptions;
  }

  return options.value.filter((option) =>
    props.selectedOptions.some(
      (selectedOption) =>
        selectedOption[props.idValue] === option[props.idValue]
    )
  );
});

const allOptionsInList = computed(() => [
  ...displayedNotSelectedOptions.value,
  ...displayedSelectedOptions.value,
  ...props.partiallySelectedOptions,
]);

const { selectedIndex, selectNext, selectPrevious } =
  useKeyboardSelection(allOptionsInList);

function onShow() {
  isOpen.value = true;
  debouncedSearch();
  inputEl.value?.focus();
  inputEl.value?.select();
  selectedIndex.value = null;
}

function onHide() {
  isOpen.value = false;
  searchText.value = null;
}

const debouncedSearch = debounce(
  async () => {
    if (
      searchText.value === null ||
      searchText.value.length < MIN_SEARCH_LENGTH
    ) {
      options.value = [];
      return;
    }
    isLoading.value = true;

    try {
      options.value = await props.findOptions(searchText.value || "");
    } catch (error) {
      console.error("Error fetching options:", error);
      options.value = [];
    } finally {
      isLoading.value = false;
    }
  },
  300,
  { leading: true }
);

watch(searchText, debouncedSearch);

function updateSelectedOptionsInList() {
  if (!isOpen.value) return;
  const selectedIds = props.selectedOptions.map(
    (option) => option[props.idValue]
  );
  const partiallySelectedIds = props.partiallySelectedOptions.map(
    (option) => option[props.idValue]
  );

  notSelectedOptionsInList.value = options.value.filter(
    (option) =>
      !selectedIds.includes(option[props.idValue]) &&
      !partiallySelectedIds.includes(option[props.idValue])
  );
}
watchEffect(updateSelectedOptionsInList);

function isSelectedOption(option: Option) {
  const selectedIds = props.selectedOptions.map(
    (option) => option[props.idValue]
  );
  return selectedIds.includes(option[props.idValue]);
}

function toggleOption(option: Option) {
  if (isSelectedOption(option)) {
    emit("unselect", option);
  } else {
    emit("select", option);
  }
  inputEl.value?.focus();
  inputEl.value?.select();
}

function handleEnter() {
  if (selectedIndex.value !== null) {
    toggleOption(allOptionsInList.value[selectedIndex.value]);
  }
}
</script>

<style scoped lang="scss">
.loading-indicator-container {
  height: 4px;
  width: 100%;
}
</style>
