<template>
  <div class="row items-center gap-x-sm">
    <FilterChip
      v-if="filterOptions.inboxId"
      :icon="icons.inbox"
      @remove="removeFilter('inboxId')"
    >
      {{ $t("filterChips.nInboxes", { n: filterOptions.inboxId.length }) }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.inquiryShortCode"
      :icon="icons.inquiry.shortCode"
      @remove="removeFilter('inquiryShortCode')"
    >
      {{ filterOptions.inquiryShortCode.join(", ") }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.customer?.length"
      :icon="icons.customer"
      @remove="removeFilter('customer')"
    >
      {{ filterOptions.customer.join(", ") }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.buildingProject"
      :icon="icons.inquiry.buildingProject"
      @remove="removeFilter('buildingProject')"
    >
      {{ filterOptions.buildingProject.join(", ") }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.status?.length"
      :icon="icons.inquiry.status"
      @remove="removeFilter('status')"
    >
      {{
        filterOptions.status
          ?.map((status) => t(`quotationStatus.${status}`))
          .join(", ")
      }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.assignedUserId?.length"
      :icon="icons.user.main"
      @remove="removeFilter('assignedUserId')"
    >
      {{ t("filterChips.nUsers", { n: filterOptions.assignedUserId.length }) }}
    </FilterChip>
  </div>
</template>

<script setup lang="ts">
import FilterChip from "@/components/FilterChip.vue";
import icons from "@/config/icons";
import { useCurrentQuotationsStore } from "@/stores/currentQuotations";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const store = useCurrentQuotationsStore();
const { filterOptions } = storeToRefs(store);
const { removeFilter } = store;
</script>
