<template>
  <q-list separator v-if="inquiry?.hasQuotation">
    <q-item
      clickable
      v-ripple
      v-for="fileFormat in Object.values(QuotationFileFormat)"
      :key="fileFormat as string"
      dense
      @click.stop.prevent="downloadQuotation(inquiry.id, fileFormat)"
    >
      <q-item-section>
        <q-item-label class="ellipsis">
          <q-icon :name="icons[fileFormat]" class="q-mr-sm" color="neutral-8" />
          {{ inquiry.shortCode }} - {{ inquiry.buildingProject }}.{{
            fileFormat.toLowerCase()
          }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { downloadQuotation } from "@/api/draftQuotation";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { QuotationFileFormat } from "@/types/quotation";
import { storeToRefs } from "pinia";

const { inquiry } = storeToRefs(useCurrentInquiryStore());

const icons = {
  [QuotationFileFormat.PDF]: "sym_r_description",
  [QuotationFileFormat.P94]: "sym_r_code",
  [QuotationFileFormat.X94]: "sym_r_code",
};
</script>
