<template>
  <q-menu
    ref="menu"
    self="bottom middle"
    anchor="top middle"
    :offset="[0, 2]"
    @keydown.stop
    @keydown.stop.esc="closeMenu"
  >
    <q-card>
      <q-card-section class="column no-wrap items-start gap-sm">
        <currency-input
          dense
          outlined
          v-autoselect
          class="full-width"
          input-class="text-right"
          :label="$t('offerPosition.listPrice')"
          :model-value="position.unitPrice"
          @update:model-value="updateListPrice"
          :disable="!position.product.isPriceAdjustable"
          :autofocus="position.product.isPriceAdjustable"
        />
        <q-input
          dense
          outlined
          v-autoselect
          type="number"
          class="full-width"
          input-class="text-right"
          suffix="%"
          :label="$t('offerPosition.discountRate')"
          :model-value="discountString"
          @update:model-value="updateDiscount"
          :disable="!organization?.usePositionDiscounts"
          :autofocus="!position.product.isPriceAdjustable"
        />
        <currency-input
          dense
          outlined
          v-autoselect
          class="full-width"
          input-class="text-right"
          :label="$t('offerPosition.unitPrice')"
          :model-value="discountedUnitPrice"
          @update:model-value="updateUnitPrice"
          :disable="!organization?.usePositionDiscounts"
        />
        <template v-if="organization?.useGrossMargins">
          <q-separator class="full-width" />
          <currency-input
            dense
            outlined
            v-autoselect
            class="full-width"
            input-class="text-right"
            :label="$t('offerPosition.purchasePrice')"
            :model-value="position.purchasePrice"
            @update:model-value="updatePurchasePrice"
            :disable="!position.product.isPriceAdjustable"
          />
          <div class="full-width text-right row no-wrap items-baseline">
            {{ $t("offerPosition.margin") }}:
            <q-space />
            {{ margin ? margin.toFixed(1) : "-" }} %
          </div>
        </template>
      </q-card-section>
    </q-card>
  </q-menu>
</template>

<script setup lang="ts">
import { DISCOUNT_PRECISION } from "@/config/constants";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import {
  calculateDiscountFromTargetUnitPrice,
  calculatePositionMargin,
  calculatePositionUnitPrice,
} from "@/utils/pricing";
import { storeToRefs } from "pinia";
import type { QMenu } from "quasar";
import { computed, ref } from "vue";
import CurrencyInput from "./CurrencyInput.vue";

const props = defineProps<{
  groupId: number;
  position: OfferPosition;
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const store = useCurrentOfferPositionGroupsStore();

const discountedUnitPrice = computed(() => {
  return calculatePositionUnitPrice(
    props.position.unitPrice,
    props.position.discountRate
  );
});

const margin = computed(() => {
  return calculatePositionMargin(
    props.position.unitPrice,
    props.position.discountRate,
    props.position.purchasePrice
  );
});

const discountString = computed(() => {
  return props.position.discountRate
    ? props.position.discountRate.toFixed(DISCOUNT_PRECISION)
    : null;
});

const updateListPrice = (value: string | number | null) => {
  store.updateOfferPosition(props.groupId, props.position.id, {
    unitPrice: value !== null ? Number(value) : null,
  });
};

const updateDiscount = (value: string | number | null) => {
  store.updateOfferPosition(props.groupId, props.position.id, {
    discountRate: value !== null ? Number(value) : null,
  });
};

const updateUnitPrice = (value: string | number | null) => {
  const discountRate = calculateDiscountFromTargetUnitPrice(
    value !== null ? Number(value) : null,
    props.position.unitPrice
  );
  store.updateOfferPosition(props.groupId, props.position.id, {
    discountRate: discountRate,
  });
};

const updatePurchasePrice = (value: string | number | null) => {
  store.updateOfferPosition(props.groupId, props.position.id, {
    purchasePrice: value !== null ? Number(value) : null,
  });
};

const menu = ref<QMenu | null>(null);
const closeMenu = () => {
  menu.value?.hide();
};
</script>
