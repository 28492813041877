import { client } from "@/api/client/client";
import type { ProductGroupDiscount } from "@/types/productGroupDiscount";

export async function listProductGroupDiscounts(
  inquiryId: number
): Promise<ProductGroupDiscount[]> {
  const response = await client.get<ProductGroupDiscount[]>(
    `/inquiries/${inquiryId}/product_group_discounts/`
  );
  return response.data;
}

export async function createProductGroupDiscount(
  inquiryId: number,
  productGroupId: number,
  data: Partial<Omit<ProductGroupDiscount, "productGroup">>
): Promise<ProductGroupDiscount> {
  const response = await client.post<ProductGroupDiscount>(
    `/inquiries/${inquiryId}/product_group_discounts/`,
    { productGroupId: productGroupId, ...data }
  );
  return response.data;
}

export async function updateProductGroupDiscount(
  inquiryId: number,
  productGroupDiscountId: number,
  data: Partial<Omit<ProductGroupDiscount, "productGroup">>
): Promise<ProductGroupDiscount> {
  const response = await client.patch<ProductGroupDiscount>(
    `/inquiries/${inquiryId}/product_group_discounts/${productGroupDiscountId}/`,
    data
  );
  return response.data;
}

export async function deleteProductGroupDiscount(
  inquiryId: number,
  productGroupDiscountId: number
): Promise<void> {
  await client.delete(
    `/inquiries/${inquiryId}/product_group_discounts/${productGroupDiscountId}/`
  );
}
