export default {
  supplier: "sym_r_factory",
  supplierRfq: "sym_r_output",
  product: "sym_r_category",
  customer: "sym_r_groups",
  delete: "sym_r_delete",
  addSupplierRfq: "sym_r_add",
  removeSupplierRfq: "sym_r_remove",
  supplierRfqStatus: {
    DRAFT: "sym_r_draft",
    SENT: "sym_r_send",
    ANSWERED: "sym_r_reply",
    markAsAnswered: "sym_r_check",
    markAsUnanswered: "sym_r_close",
  },
  inquiry: {
    main: "sym_r_draft",
    shortCode: "sym_r_tag",
    buildingProject: "sym_r_apartment",
    status: "sym_r_assignment",
    tag: "sym_r_label",
    sender: "sym_r_alternate_email",
  },
  filter: "sym_r_filter_list",
  inbox: "sym_r_inbox",
  quotation: {
    main: "sym_r_description",
    serialId: "sym_r_tag",
    createdDate: "sym_r_event",
    statusOptions: {
      CREATED: "sym_r_draft",
      ACCEPTED: "sym_r_check",
      DECLINED: "sym_r_close",
    },
    status: "sym_r_assignment",
    acceptedOrDeclinedAt: "sym_r_check_circle",
    totalPriceNet: "sym_r_money",
    positionCount: "sym_r_list",
  },
  user: {
    main: "sym_r_person",
  },
  documentTypes: {
    pdf: "sym_r_draft",
    gaeb: "sym_r_code",
  },
  discount: "sym_r_sell",
  margin: "sym_r_stacked_line_chart",
};
