<template>
  <q-btn
    dense
    outline
    color="neutral-5"
    :disable="disabled"
    class="supplier-rfq-select-button bg-white"
    @click.stop
    @mousedown.stop.prevent
  >
    <div
      class="row items-center no-wrap"
      :class="{ 'text-neutral-7': numSupplierRfqs > numSupplierRfqsAnswered }"
    >
      <span v-if="numSupplierRfqs > 0">
        {{ numSupplierRfqsAnswered }}/{{ numSupplierRfqs }}
      </span>
      <q-icon :name="icons.supplierRfq" size="16px" />
    </div>
    <supplier-select-menu />
    <q-tooltip :delay="1000" v-if="numSupplierRfqs > 0">
      {{
        $t("supplierSelectButton.tooltip", {
          numSupplierRfqsAnswered,
          numSupplierRfqs,
        })
      }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { computed } from "vue";
import SupplierSelectMenu from "./SupplierSelectMenu.vue";

const props = defineProps<{
  group: OfferPositionGroup;
  disabled: boolean;
}>();

const numSupplierRfqs = computed(() => {
  return (
    props.group.supplierRfqsDraft.length +
    props.group.supplierRfqsNotDraft.length
  );
});

const numSupplierRfqsAnswered = computed(() => {
  return props.group.supplierRfqsNotDraft.filter(
    (rfq) => rfq.status === "ANSWERED"
  ).length;
});
</script>

<style scoped lang="scss">
.supplier-rfq-select-button {
  flex-grow: 0;
  flex-shrink: 0;
  width: fit-content;
  font-size: inherit;
  font-weight: inherit;
  height: 24px;
}
</style>
