<template>
  <q-input
    type="text"
    v-model="content"
    placeholder="..."
    dense
    outlined
    @update:model-value="updateContent"
  />
</template>
<script setup lang="ts">
import type { AutomationRule, Condition } from "@/types/automationRule";
import { ref } from "vue";
import * as api from "@/api/automationRule";

const props = defineProps<{
  condition: Condition;
  automationRule: AutomationRule;
}>();
const content = ref<string>(props.condition.content);

async function updateContent(newContent: any) {
  content.value = newContent.toString();
  await api.updateCondition(props.automationRule.id, props.condition.id, {
    content: content.value,
  });
}
</script>

<style scoped lang="scss"></style>
