<template>
  <q-expansion-item
    class="border-1 border-neutral-3 rounded-borders"
    expand-separator
  >
    <template #header>
      <q-item-section class="row no-wrap">
        <q-item-label>
          {{ automationRule.name }}
        </q-item-label>
      </q-item-section>
      <q-item-section side>
        <q-btn
          flat
          dense
          size="sm"
          color="neutral-5"
          icon="delete"
          @click.stop.prevent="$emit('delete')"
        />
      </q-item-section>
    </template>
    <q-separator />
    <div class="column no-wrap gap-y-md q-pa-md">
      <div class="column no-wrap gap-y-sm">
        <q-input
          dense
          outlined
          type="text"
          v-model="automationRule.name"
          placeholder="Name"
          label="Name"
          @update:model-value="updateName"
        />
      </div>
      <div class="column no-wrap gap-y-sm">
        <div class="text-neutral-7 text-bold">
          {{ $t("automationRule.conditions") }}
        </div>
        <template
          v-for="(condition, idx) in automationRule.conditions"
          :key="condition.id"
        >
          <div class="row no-wrap items-start gap-x-sm full-width">
            <operator-input
              v-if="idx != 0"
              :condition="condition"
              :automation-rule="automationRule"
            />
            <where-placeholder v-else />
            <div
              class="column no-wrap gap-y-sm full-width border-neutral-3 rounded-borders"
              :class="{ 'border-1 q-pa-sm': condition.children.length > 0 }"
            >
              <div class="row no-wrap items-center gap-x-sm full-width">
                <where-placeholder v-if="condition.children.length > 0" />
                <check-condition-input
                  :condition="condition"
                  :automation-rule="automationRule"
                />
                <condition-content-input
                  class="col"
                  :condition="condition"
                  :automation-rule="automationRule"
                />
                <q-btn
                  v-if="condition.children.length === 0"
                  flat
                  dense
                  color="neutral-7"
                  icon="sym_r_add"
                  @click="addCondition([condition.id])"
                />
                <q-btn
                  flat
                  dense
                  color="neutral-7"
                  icon="sym_r_remove"
                  @click="deleteCondition([condition.id])"
                />
              </div>
              <div
                class="column no-wrap gap-y-sm"
                v-if="condition.children.length > 0"
              >
                <automation-rule-condition
                  v-for="child in condition.children"
                  :condition="child"
                  :automation-rule="automationRule"
                  :key="child.id"
                  @delete-condition="
                    deleteCondition([condition.id].concat($event))
                  "
                  @add-condition="addCondition([condition.id].concat($event))"
                />
                <q-btn
                  flat
                  dense
                  color="neutral-7"
                  icon="sym_r_add"
                  @click="addCondition([condition.id])"
                />
              </div>
            </div>
          </div>
        </template>
        <q-btn
          flat
          dense
          color="neutral-7"
          icon="sym_r_add"
          @click="addCondition([])"
        />
      </div>
      <div class="column no-wrap gap-y-sm">
        <div class="text-neutral-7 text-bold">
          {{ $t("automationRule.action") }}
        </div>
        <single-tag-select-field
          :automation-rule="automationRule"
          :available-tags="availableTags"
          @select="selectTag"
        />
      </div>
    </div>
  </q-expansion-item>
</template>
<script setup lang="ts">
import * as api from "@/api/automationRule";
import { useRouteParams } from "@/composables/useRouteParams";
import type { AutomationRule } from "@/types/automationRule";
import type { Tag } from "@/types/tag";
import { ref } from "vue";
import AutomationRuleCondition from "./AutomationRuleCondition.vue";
import CheckConditionInput from "./CheckConditionInput.vue";
import ConditionContentInput from "./ConditionContentInput.vue";
import OperatorInput from "./OperatorInput.vue";
import SingleTagSelectField from "./SingleTagSelectField.vue";
import WherePlaceholder from "./WherePlaceholder.vue";

const props = defineProps<{
  automationRule: AutomationRule;
  availableTags: Tag[];
}>();

defineEmits<{
  delete: [];
}>();

const automationRule = ref<AutomationRule>(props.automationRule);
const { inboxId } = useRouteParams();

async function selectTag(tag: Tag) {
  automationRule.value.tag = tag;
  await api.updateAutomationRule(inboxId.value, automationRule.value.id, {
    tagId: tag.id,
  });
}

async function updateName(newName: string | number | null) {
  automationRule.value.name = newName?.toString() || "";
  await api.updateAutomationRule(inboxId.value, automationRule.value.id, {
    name: automationRule.value.name,
  });
}

async function addCondition(parentIds: number[]) {
  const parentId = parentIds ? parentIds[parentIds.length - 1] : null;
  const newCondition = await api.createCondition(
    automationRule.value.id,
    parentId
  );
  if (parentId) {
    parentIds.reduce((conditions, parentId, idx) => {
      const parentIndex = conditions.findIndex((c) => c.id === parentId);
      if (idx === parentIds.length - 1) {
        conditions[parentIndex].children.push(newCondition);
      } else {
        return conditions[parentIndex].children;
      }
      return conditions;
    }, automationRule.value.conditions);
  } else {
    automationRule.value.conditions.push(newCondition);
  }
}

async function deleteCondition(conditionIds: number[]) {
  conditionIds.reduce((conditions, conditionId, idx) => {
    const conditionIndex = conditions.findIndex((c) => c.id === conditionId);
    if (idx === conditionIds.length - 1) {
      conditions.splice(conditionIndex, 1);
      api.deleteCondition(automationRule.value.id, conditionId);
    } else {
      return conditions[conditionIndex].children;
    }
    return conditions;
  }, automationRule.value.conditions);
}
</script>
