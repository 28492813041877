<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section
        class="q-gutter-y-sm"
        @keydown.enter.stop.prevent="onOKClick"
      >
        <q-input
          ref="nameInput"
          v-model="name"
          :label="$t('customStatus.name')"
          outlined
          dense
          autofocus
          lazy-rules
          :rules="[
            (val) => !!val || $t('settings.inbox.customStatuses.nameRequired'),
          ]"
        />
        <q-select
          v-model="originalStatus"
          :display-value="$t(originalStatus || '')"
          :label="$t('customStatus.originalStatus')"
          :option-label="(option) => $t(option)"
          outlined
          dense
          :options="originalStatusOptions"
          :rules="[
            (val) =>
              (!!val && val.trim() !== '') ||
              $t('settings.inbox.customStatuses.originalStatusRequired'),
          ]"
        />
        <q-input
          ref="colorInput"
          :label="$t('customStatus.color')"
          outlined
          dense
          v-model="color"
          :rules="['anyColor']"
        >
          <template v-slot:prepend>
            <div
              :style="{
                height: '16px',
                width: '16px',
                borderRadius: '8px',
                backgroundColor: color,
              }"
            />
          </template>
          <template v-slot:append>
            <q-icon name="sym_r_colorize" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-color v-model="color" format-model="hex" />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat dense label="Cancel" @click="onDialogCancel" />
        <q-btn
          dense
          color="primary"
          label="OK"
          @click="onOKClick"
          :disable="
            !name ||
            nameInput?.hasError ||
            colorInput?.hasError ||
            !originalStatus
          "
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { DEFAULT_CUSTOM_STATUS_COLOR } from "@/config/constants";
import type { CustomStatus } from "@/types/customStatus";
import { QInput, useDialogPluginComponent } from "quasar";
import { ref, watchEffect } from "vue";
import {
  type BaseInquiryStatus,
  CHANGEABLE_BASE_INQUIRY_STATUSES,
} from "@/types/inquiry";

const props = defineProps<{
  customStatus?: CustomStatus;
}>();

const name = ref(props.customStatus?.name);
const originalStatus = ref<BaseInquiryStatus | undefined>(
  props.customStatus?.originalStatus
);
const color = ref(props.customStatus?.color || DEFAULT_CUSTOM_STATUS_COLOR);
watchEffect(() => {
  name.value = props.customStatus?.name;
  color.value = props.customStatus?.color || DEFAULT_CUSTOM_STATUS_COLOR;
});

const nameInput = ref<QInput | null>(null);
const colorInput = ref<QInput | null>(null);

const originalStatusOptions = CHANGEABLE_BASE_INQUIRY_STATUSES;

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

function onOKClick() {
  onDialogOK({
    name: name.value,
    color: color.value,
    originalStatus: originalStatus.value,
  });
}
</script>
