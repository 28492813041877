<template>
  <inquiry-values-banner
    v-if="shouldShow"
    icon="sym_r_warning"
    color="warning"
    :title="$t('inquiryPage.analyzedInDifferentInbox.title')"
  >
    <template #default>
      <div class="content-wrapper">
        {{ $t("inquiryPage.analyzedInDifferentInbox.message") }}
        <q-btn
          outline
          flat
          dense
          color="warning"
          @click="handleRetryAnalysis"
          :label="$t('inquiryPage.analyzedInDifferentInbox.retryButton')"
          class="q-mt-sm"
        />
      </div>
    </template>
  </inquiry-values-banner>
</template>

<script setup lang="ts">
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useInboxesStore } from "@/stores/inboxes";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import InquiryValuesBanner from "./InquiryValuesDisplay/InquiryValuesBanner.vue";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { inbox } = storeToRefs(useCurrentInboxStore());
const { getById } = useInboxesStore();

const { retryAnalysis } = useCurrentInquiryStore();

const isRetrying = ref(false);

const shouldShow = computed(() => {
  if (
    isRetrying.value ||
    !inquiry.value?.analyzedInInboxId ||
    inquiry.value?.status.originalStatus !== "REVIEWING"
  )
    return false;

  const analyzedInbox = getById(inquiry.value.analyzedInInboxId);

  return (
    inquiry.value.analyzedInInboxId !== inbox.value?.id &&
    (inbox.value?.matchInInboxOnly || analyzedInbox?.value?.matchInInboxOnly)
  );
});

async function handleRetryAnalysis() {
  if (!inquiry.value) return;

  isRetrying.value = true;
  try {
    await retryAnalysis();
  } finally {
    isRetrying.value = false;
  }
}
</script>

<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
