<template>
  <div
    class="total-price-display text-right"
    :class="{ alternative: position.isAlternative }"
  >
    {{ totalPriceString }}
    <q-tooltip :delay="1000">
      {{ tooltipText }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { DEFAULT_CURRENCY } from "@/config/defaults";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import { calculatePositionTotalPrice } from "@/utils/pricing";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale, t } = useI18n();

const props = defineProps<{
  position: OfferPosition;
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const totalPrice = computed(() => {
  return calculatePositionTotalPrice(props.position);
});

const totalPriceString = computed(() => {
  if (totalPrice.value === null) {
    return "?";
  }
  const priceString = totalPrice.value.toLocaleString(locale.value, {
    style: "currency",
    currency: organization.value?.details.currency ?? DEFAULT_CURRENCY,
  });
  return props.position.isAlternative ? `* ${priceString}` : priceString;
});

const tooltipText = computed(() => {
  if (totalPrice.value === null) {
    return t("offerPosition.unitPriceUnknown");
  }
  return props.position.isAlternative
    ? t("offerPosition.totalPriceAlternative")
    : t("offerPosition.totalPrice");
});
</script>

<style scoped lang="scss">
.total-price-display {
  min-width: $price-column-width;

  &.alternative {
    color: $neutral-7;
  }
}
</style>
