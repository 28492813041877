<template>
  <AsyncMultiSelectMenu
    :findOptions="findSuppliers"
    :selectedOptions="selectedSuppliers"
    :partiallySelectedOptions="[]"
    :searchPlaceholder="$t('supplierSelectMenu.searchPlaceholder')"
    :labelFn="(option: ShortSupplier) => option.name"
    idValue="id"
    :preloadOptions="[]"
    @select="selectSupplier"
    @unselect="unselectSupplier"
  />
</template>

<script setup lang="ts">
import {
  findSuppliers as apiFindSuppliers,
  getSupplier as apiGetSupplier,
} from "@/api/supplier";
import AsyncMultiSelectMenu from "@/components/SelectMenus/AsyncMultiSelectMenu.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Supplier } from "@/types/supplier";
import { computed, ref, watch } from "vue";

const props = defineProps<{
  selectedSupplierIds: number[];
}>();

const emit = defineEmits<{
  "update:selectedSupplierIds": [supplierIds: number[]];
}>();

type ShortSupplier = Pick<Supplier, "id" | "name">;

const { organizationId } = useRouteParams();

const cachedSuppliers = ref<Record<number, ShortSupplier>>({});

const selectedSuppliers = computed(() =>
  props.selectedSupplierIds.map((id) => ({
    id,
    name: cachedSuppliers.value[id]?.name ?? "",
  }))
);

// Populate cache with selected suppliers
watch(
  () => props.selectedSupplierIds,
  async (ids) => {
    for (const id of ids) {
      if (cachedSuppliers.value[id]) continue;

      const supplier = await apiGetSupplier(organizationId.value, id);
      cachedSuppliers.value[id] = shorten(supplier);
    }
  },
  { immediate: true }
);

async function findSuppliers(searchText: string) {
  if (!organizationId.value) return [];

  const suppliers = await apiFindSuppliers(organizationId.value, searchText);
  return suppliers.map(shorten);
}

async function selectSupplier(supplier: ShortSupplier) {
  if (props.selectedSupplierIds.includes(supplier.id)) return;
  cachedSuppliers.value[supplier.id] = supplier;
  emit("update:selectedSupplierIds", [
    ...props.selectedSupplierIds,
    supplier.id,
  ]);
}

async function unselectSupplier(supplier: ShortSupplier) {
  emit(
    "update:selectedSupplierIds",
    props.selectedSupplierIds.filter((id) => id !== supplier.id)
  );
}

function shorten(supplier: Supplier) {
  return {
    id: supplier.id,
    name: supplier.name,
  };
}
</script>
