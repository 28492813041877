<template>
  <div class="row items-center gap-x-sm">
    <FilterChip
      v-if="filterOptions.status?.length"
      :icon="icons.inquiry.status"
      @remove="removeFilter('status')"
    >
      {{
        filterOptions.status
          ?.map((status) => t(`supplierRfqStatus.${status}`))
          .join(", ")
      }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.assignedUserId?.length"
      :icon="icons.user.main"
      @remove="removeFilter('assignedUserId')"
    >
      {{
        isOnlyAssignedToMe
          ? t("filterChips.assignedToMe")
          : t("filterChips.nUsers", { n: filterOptions.assignedUserId.length })
      }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.supplierId?.length"
      :icon="icons.supplier"
      @remove="removeFilter('supplierId')"
    >
      {{
        $t("supplier.nLabel", {
          n: filterOptions.supplierId.length,
        })
      }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.buildingProject"
      :icon="icons.inquiry.buildingProject"
      @remove="removeFilter('buildingProject')"
    >
      {{ filterOptions.buildingProject }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.inquiryShortCode"
      :icon="icons.inquiry.shortCode"
      @remove="removeFilter('inquiryShortCode')"
    >
      {{ filterOptions.inquiryShortCode }}
    </FilterChip>
  </div>
</template>

<script setup lang="ts">
import FilterChip from "@/components/FilterChip.vue";
import icons from "@/config/icons";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import { useCurrentUserStore } from "@/stores/currentUser";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const currentSupplierRfqsStore = useCurrentSupplierRfqsStore();

const { filterOptions } = storeToRefs(currentSupplierRfqsStore);

const removeFilter = (filterKey: string) => {
  currentSupplierRfqsStore.removeFilter(filterKey);
};

const { user } = storeToRefs(useCurrentUserStore());

const isOnlyAssignedToMe = computed(() => {
  return (
    filterOptions.value.assignedUserId?.length === 1 &&
    filterOptions.value.assignedUserId?.[0] === user.value?.id
  );
});
</script>
