import * as supplierRfqApi from "@/api/supplierRfq";
import { useRouteParams } from "@/composables/useRouteParams";
import { DEFAULT_PAGE_SIZE_FOR_SUPPLIER_RFQS } from "@/config/constants";
import type {
  SupplierRFQ,
  SupplierRFQStatus,
  SupplierRFQWrite,
} from "@/types/supplierRfq";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useRouter, type LocationQueryValue } from "vue-router";

const RELEVANT_ROUTES = [
  "supplier-rfqs",
  "supplier-rfqs.current-supplier-rfq",
  "inquiry-supplier-rfqs",
  "inquiry-supplier-rfqs.current-supplier-rfq",
];

export const useCurrentSupplierRfqsStore = defineStore(
  "currentSupplierRfqs",
  () => {
    const { organizationId } = useRouteParams();
    const { currentRoute } = useRouter();
    const router = useRouter();

    const supplierRfqs = ref<SupplierRFQ[]>([]);
    const numResults = ref<number | null>(null);
    const nextCursor = ref<string | null>(null);
    const previousCursor = ref<string | null>(null);
    const pageNumber = ref<number>(1);
    const pageSize = ref<number>(DEFAULT_PAGE_SIZE_FOR_SUPPLIER_RFQS);

    const filterOptions = ref<supplierRfqApi.ListSupplierRfqFilterOptions>({});

    const isSupplierRfqsRouteActive = () =>
      currentRoute.value &&
      RELEVANT_ROUTES.includes(currentRoute.value.name as string);

    function clearSupplierRfqs() {
      supplierRfqs.value = [];
      numResults.value = null;
      nextCursor.value = null;
      previousCursor.value = null;
      pageNumber.value = 1;
      pageSize.value = DEFAULT_PAGE_SIZE_FOR_SUPPLIER_RFQS;
      filterOptions.value = {};
    }

    function loadFilterOptionsFromRoute() {
      const query = currentRoute.value.query;
      const params = currentRoute.value.params;

      const inquiryIdFilterFromParams = params.inquiryId
        ? parseInt(params.inquiryId as string)
        : undefined;

      const inquiryIdFilterFromQuery = query.inquiryId
        ? parseInt(query.inquiryId as string)
        : undefined;

      filterOptions.value = {
        status: parseStatusQueryParam(query.status),
        assignedUserId: parseAssignedUserIdQueryParam(query.assignedUserId),
        inquiryId: inquiryIdFilterFromParams ?? inquiryIdFilterFromQuery,
        buildingProject: query.buildingProject as string,
        inquiryShortCode: query.inquiryShortCode as string,
        supplierId: parseSupplierIdsQueryParam(query.supplierId),
      };
    }

    async function fetchSupplierRfqs(cursor: string | null = null) {
      const { data, next, previous, count } =
        await supplierRfqApi.listSupplierRfqs(
          organizationId.value,
          pageSize.value,
          cursor,
          filterOptions.value
        );
      supplierRfqs.value = data;
      numResults.value = count;
      nextCursor.value = next;
      previousCursor.value = previous;
    }

    async function reloadSupplierRfqs() {
      pageNumber.value = 1;
      await fetchSupplierRfqs();
    }

    async function goToNextPage() {
      if (!nextCursor.value) {
        return;
      }

      pageNumber.value = pageNumber.value + 1;
      await fetchSupplierRfqs(nextCursor.value);
    }

    async function goToPreviousPage() {
      if (!previousCursor.value) {
        return;
      }

      pageNumber.value = pageNumber.value - 1;
      await fetchSupplierRfqs(previousCursor.value);
    }

    async function updateSupplierRfq(
      supplierRfqId: number,
      data: Partial<SupplierRFQ>
    ) {
      const supplierRfq = supplierRfqs.value.find(
        (supplierRfq) => supplierRfq.id === supplierRfqId
      );

      if (!supplierRfq) {
        throw new Error("Supplier RFQ not found");
      }

      const updatedSupplierRfq = await supplierRfqApi.updateSupplierRfq(
        supplierRfq.inquiry.id,
        supplierRfqId,
        convertToWriteFormat(data)
      );

      Object.assign(supplierRfq, updatedSupplierRfq);
    }

    async function sendSupplierRfq(supplierRfqId: number) {
      const supplierRfq = supplierRfqs.value.find(
        (supplierRfq) => supplierRfq.id === supplierRfqId
      );

      if (!supplierRfq) {
        throw new Error("Supplier RFQ not found");
      }

      const updatedSupplierRfq = await supplierRfqApi.sendSupplierRfq(
        supplierRfq.inquiry.id,
        supplierRfqId
      );

      supplierRfq.status = "SENT";

      Object.assign(supplierRfq, updatedSupplierRfq); // update sentAt
    }

    async function setPageSize(newPageSize: number) {
      pageSize.value = newPageSize;
      await reloadSupplierRfqs();
    }

    function removeFilter(filterKey: string) {
      filterOptions.value = {
        ...filterOptions.value,
        [filterKey]: undefined,
      };

      const query = { ...router.currentRoute.value.query };
      delete query[filterKey];
      router.replace({ query });

      reloadSupplierRfqs();
    }

    async function deleteSupplierRfq(supplierRfqId: number) {
      const supplierRfq = supplierRfqs.value.find(
        (supplierRfq) => supplierRfq.id === supplierRfqId
      );

      if (!supplierRfq) {
        throw new Error("Supplier RFQ not found");
      }

      supplierRfqs.value = supplierRfqs.value.filter(
        (supplierRfq) => supplierRfq.id !== supplierRfqId
      );

      try {
        await supplierRfqApi.deleteSupplierRfq(
          supplierRfq.inquiry.id,
          supplierRfqId
        );
      } catch (error) {
        supplierRfqs.value.push(supplierRfq);
      }
    }

    function addFilter(
      filterKey: keyof supplierRfqApi.ListSupplierRfqFilterOptions,
      filterValue: supplierRfqApi.ListSupplierRfqFilterOptions[keyof supplierRfqApi.ListSupplierRfqFilterOptions]
    ) {
      filterOptions.value = {
        ...filterOptions.value,
        [filterKey]: filterValue,
      };

      const query = { ...router.currentRoute.value.query };
      if (filterValue !== undefined) {
        query[filterKey] = filterValue ? filterValue.toString() : "null";
      } else {
        delete query[filterKey];
      }
      router.replace({ query });

      reloadSupplierRfqs();
    }

    watch(
      () => currentRoute.value,
      async (newCurrentRoute, oldCurrentRoute) => {
        if (!isSupplierRfqsRouteActive()) {
          clearSupplierRfqs();
          return;
        }

        if (
          newCurrentRoute.params.organizationId !==
            oldCurrentRoute?.params.organizationId ||
          newCurrentRoute.name !== oldCurrentRoute?.name ||
          newCurrentRoute.params.inquiryId !== oldCurrentRoute?.params.inquiryId
        ) {
          loadFilterOptionsFromRoute();
          await reloadSupplierRfqs();
        }
      },
      { immediate: true }
    );

    return {
      supplierRfqs,
      updateSupplierRfq,
      reloadSupplierRfqs,
      goToNextPage,
      goToPreviousPage,
      pageNumber,
      pageSize,
      numResults,
      nextCursor,
      previousCursor,
      setPageSize,
      filterOptions,
      removeFilter,
      addFilter,
      sendSupplierRfq,
      deleteSupplierRfq,
    };
  }
);

function convertToWriteFormat(
  supplierRfq: Partial<SupplierRFQ>
): Partial<SupplierRFQWrite> {
  const { assignedUser, supplier, ...rest } = supplierRfq;
  return {
    ...rest,
    assignedUserId: assignedUser === null ? null : assignedUser?.id,
    supplierId: supplier?.id,
  };
}

function parseStatusQueryParam(
  queryValue: LocationQueryValue | LocationQueryValue[]
): SupplierRFQStatus[] {
  if (!queryValue) {
    return [];
  }

  if (Array.isArray(queryValue)) {
    return queryValue as SupplierRFQStatus[];
  }

  return queryValue.split(",") as SupplierRFQStatus[];
}

function parseAssignedUserIdQueryParam(
  queryValue: LocationQueryValue | LocationQueryValue[]
): (number | null)[] {
  return parseQueryParamArrayOrValue(queryValue, parseUserId);
}

function parseSupplierIdsQueryParam(
  queryValue: LocationQueryValue | LocationQueryValue[]
): number[] {
  return parseQueryParamArrayOrValue(queryValue, parseInt);
}

function parseQueryParamArrayOrValue<T>(
  queryValue: LocationQueryValue | LocationQueryValue[],
  valueParser: (value: string) => T
): T[] {
  if (!queryValue) {
    return [];
  }

  if (Array.isArray(queryValue)) {
    return (queryValue as string[]).map(valueParser);
  }

  const rawValues = (queryValue as string).split(",");
  return rawValues.map((value) => valueParser(value));
}

function parseUserId(userId: string): number | null {
  return userId === "null" ? null : parseInt(userId);
}
