<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="exportConfiguration" />
    <q-form @submit="saveExportConfiguration" class="q-pa-md">
      <q-checkbox
        v-model="exportEnabled"
        :label="$t('settings.organization.exportConfiguration.exportEnabled')"
      />
      <div v-if="exportEnabled">
        <div>
          <q-select
            class="q-mt-md"
            v-if="!isLoading"
            dense
            outlined
            v-model="renderFunction"
            :readonly="!canWrite"
            :options="RENDER_FUNCTION_TYPES"
            :option-label="(option: renderFunctionType) => RENDER_FUNCTION_TYPES_LABELS[option]"
            :label="
              $t('settings.organization.exportConfiguration.renderFunction')
            "
            type="textarea"
          />
          <q-skeleton type="QInput" style="height: 129px" v-else />
        </div>
        <div>
          <q-select
            class="q-mt-md"
            v-if="!isLoading"
            dense
            outlined
            v-model="exportFunction"
            :options="EXPORT_FUNCTION_TYPES"
            :option-label="(option: exportFunctionType) => EXPORT_FUNCTION_TYPES_LABELS[option]"
            :readonly="!canWrite"
            :label="
              $t('settings.organization.exportConfiguration.exportFunction')
            "
            type="textarea"
          />
          <q-skeleton type="QInput" style="height: 129px" v-else />
        </div>
        <sftp-export-configuration
          v-if="exportFunction === 'sftp_export'"
          :configuration="configuration"
          @update:configuration="configuration = $event"
        />
        <rest-export-configuration
          v-else-if="exportFunction === 'rest_export'"
          :configuration="configuration"
          @update:configuration="configuration = $event"
        />
      </div>
      <q-btn
        v-if="canSubmit"
        dense
        class="q-mt-md float-right"
        type="submit"
        color="primary"
        :label="$t('settings.organization.exportConfiguration.save')"
        :disable="isLoading"
        :loading="isLoading"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/organization";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import SftpExportConfiguration from "@/pages/Settings/Organization/Configurations/SFTPExportConfiguration.vue";
import RestExportConfiguration from "@/pages/Settings/Organization/Configurations/RestExportConfiguration.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  EXPORT_FUNCTION_TYPES,
  RENDER_FUNCTION_TYPES,
  RENDER_FUNCTION_TYPES_LABELS,
  EXPORT_FUNCTION_TYPES_LABELS,
  type exportFunctionType,
  type renderFunctionType,
  type keyable,
} from "@/types/organization";

const exportFunction = ref<exportFunctionType | null>(null);
const renderFunction = ref<renderFunctionType | null>(null);
const configuration = ref<keyable>({});
const isLoading = ref(true);

const exportEnabled = ref<boolean>(false);

const { organizationId } = useRouteParams();

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore()
);
const canWrite = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);

const canSubmit = computed(() => {
  if (!canWrite.value) return false;
  return exportFunction.value !== null && renderFunction.value !== null;
});

const q = useQuasar();
const { t } = useI18n();

async function loadExportConfiguration() {
  isLoading.value = true;
  try {
    const exportConfiguration = await api.getExportConfiguration(
      organizationId.value
    );
    exportFunction.value = exportConfiguration.exportFunction;
    renderFunction.value = exportConfiguration.renderFunction;
    configuration.value = exportConfiguration.configuration;
    exportEnabled.value = exportConfiguration.isEnabled;
  } catch (error: any) {
    // Ignore 404 errors as they are expected when the configuration is not set
    if (error.response?.status !== 404) throw error;
  } finally {
    isLoading.value = false;
  }
}
onMounted(loadExportConfiguration);

async function saveExportConfiguration() {
  isLoading.value = true;
  try {
    const data = exportEnabled.value
      ? {
          exportFunction: exportFunction.value!,
          renderFunction: renderFunction.value!,
          configuration: configuration.value,
          isEnabled: exportEnabled.value,
        }
      : {
          isEnabled: exportEnabled.value,
        };

    await api.setExportConfiguration(organizationId.value, data);
    q.notify({
      message: t("settings.organization.exportConfiguration.notifySaved"),
      color: "positive",
    });
  } catch (e: any) {
    const errorMessage = Object.entries(e.response.data)
      .map(([key, value]) => {
        const errorsList = value as string[];
        const fieldError = errorsList.map(t).join(" ");
        return `${t(key)}: ${fieldError}`;
      })
      .join("\n\n");
    q.notify({
      message: errorMessage,
      color: "negative",
    });
  } finally {
    isLoading.value = false;
  }
}
</script>
