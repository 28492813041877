<template>
  <q-item>
    <q-item-section>
      {{ discount.productGroup.name }}
    </q-item-section>
    <q-item-section side class="cursor-pointer">
      <div v-if="!isUpdating">
        {{
          $n(Number(discount.discountRate), "decimal", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}
        %
      </div>
      <q-circular-progress
        v-if="isUpdating"
        size="16px"
        color="primary"
        indeterminate
      />
      <q-popup-edit v-model="newDiscount" :disable="isUpdating" v-slot="scope">
        <q-input
          dense
          flat
          autofocus
          input-class="text-right"
          v-model="newDiscount"
          type="number"
          step="0.01"
          suffix="%"
          v-autoselect
          @keyup.enter="
            confirmUpdate();
            scope.set();
          "
        >
          <template #after>
            <q-btn
              flat
              dense
              icon="sym_r_check"
              color="primary"
              @click="
                confirmUpdate();
                scope.set();
              "
            />
          </template>
        </q-input>
      </q-popup-edit>
    </q-item-section>
    <q-item-section side>
      <q-btn
        flat
        dense
        size="sm"
        icon="sym_r_remove"
        @click="confirmDelete"
        :loading="isDeleting"
        :disable="disabled"
      />
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import type { ProductGroupOrNoGroupDiscount } from "@/types/productGroupDiscount";
import { confirmDiscountOverwrite } from "@/utils/discountOverwriteConfirmation";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  discount: ProductGroupOrNoGroupDiscount;
  isDeleting: boolean;
  isUpdating: boolean;
  disabled: boolean;
}>();

const emit = defineEmits<{
  delete: [];
  updateNoProductGroupDiscount: [number | null];
  update: [number];
}>();

const { t } = useI18n();

const newDiscount = ref(props.discount.discountRate);
watch(
  () => props.discount.discountRate,
  (newVal) => {
    newDiscount.value = newVal;
  }
);

async function confirmDelete() {
  const isConfirmed = await confirmDiscountOverwrite(
    t("productGroupDiscount.deleteDiscount.confirmMessage"),
    "deleteInquiryDiscount"
  );
  if (isConfirmed) {
    emit("delete");
  }
}

async function confirmUpdate() {
  const isConfirmed = await confirmDiscountOverwrite(
    t("productGroupDiscount.updateDiscount.confirmMessage"),
    "updateInquiryDiscount"
  );
  if (isConfirmed) {
    emit("update", newDiscount.value);
  }
}
</script>
