<template>
  <div class="unit-price-display text-right">
    {{ formattedValue }}
    <q-tooltip :delay="1000">
      {{
        isPriceForUnitUnknown
          ? $t("offerPosition.unitPriceUnknown")
          : $t("offerPosition.unitPrice")
      }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { DEFAULT_CURRENCY } from "@/config/defaults";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import { formatCurrencyOrNull } from "@/utils/currency";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  position: OfferPosition;
}>();

const { locale } = useI18n();
const { organization } = storeToRefs(useCurrentOrganizationStore());

const currency = computed(
  () => organization.value?.details.currency || DEFAULT_CURRENCY
);

const isPriceForUnitUnknown = computed(() => {
  return props.position.unit !== props.position.product.unit;
});

const discountedUnitPrice = computed(() => {
  if (props.position.unitPrice === null) return null;
  return (
    props.position.unitPrice * (1 - (props.position.discountRate ?? 0) / 100)
  );
});

const formattedValue = computed(() => {
  if (isPriceForUnitUnknown.value) return "?";
  return discountedUnitPrice.value
    ? formatCurrencyOrNull(
        locale.value,
        discountedUnitPrice.value,
        currency.value
      )
    : null;
});
</script>

<style scoped lang="scss">
.unit-price-display {
  min-width: $price-column-width;
}
</style>
