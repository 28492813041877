<template>
  <q-avatar
    size="20px"
    :text-color="supplierRfqStatusColors[props.status]"
    :style="`outline: 2px solid var(--q-${
      supplierRfqStatusColors[props.status]
    }) !important`"
  >
    <q-icon :name="icon" size="14px" />
    <slot />
  </q-avatar>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import type { SupplierRFQStatus } from "@/types/supplierRfq";
import { computed } from "vue";
import { supplierRfqStatusColors } from "@/config/colors";

const props = defineProps<{
  status: SupplierRFQStatus;
}>();

const icon = computed(() => {
  switch (props.status) {
    case "DRAFT":
      return icons.supplierRfqStatus.DRAFT;
    case "SENT":
      return icons.supplierRfqStatus.SENT;
    case "ANSWERED":
      return icons.supplierRfqStatus.ANSWERED;
  }
  throw new Error(`Invalid supplierRfq status: ${props.status}`);
});
</script>
