<template>
  <q-skeleton v-if="loadingCount > 0" />
  <template v-else-if="hasDiscounts || canEditInquiryPositions">
    <q-list
      v-if="hasDiscounts || showNewDiscountItem"
      bordered
      separator
      dense
      class="rounded-borders"
    >
      <inquiry-discount-item
        v-for="discount in discounts"
        :key="discount.id"
        :discount="discount"
        :is-deleting="isDeletingDiscountById[discount.id ?? 0] ?? false"
        :is-updating="isUpdatingDiscountById[discount.id ?? 0] ?? false"
        :disabled="!canEditInquiryPositions"
        @delete="deleteDiscount(discount.id)"
        @update="updateDiscount(discount.id, $event)"
      />
      <inquiry-discount-item
        v-if="noProductGroupDiscount"
        :discount="noProductGroupDiscount"
        :is-deleting="isDeletingDiscountById[0] ?? false"
        :is-updating="isUpdatingDiscountById[0] ?? false"
        :disabled="!canEditInquiryPositions"
        @delete="deleteDiscount(null)"
        @update="updateDiscount(null, $event)"
      />
      <new-inquiry-discount-item
        v-if="showNewDiscountItem && canEditInquiryPositions"
        :show-separator="hasDiscounts"
        :no-product-group="noProductGroup"
        :not-yet-added-product-groups="notYetAddedProductGroups"
        :discounts="discounts"
        :is-adding-discount="isAddingDiscount"
        :is-loading="loadingCount > 0"
        @add="addDiscount"
        @cancel="showNewDiscountItem = false"
      />
    </q-list>
    <div class="row no-wrap justify-end q-mt-md">
      <q-btn
        v-if="canEditInquiryPositions"
        flat
        dense
        color="neutral-7"
        label="Neuer Rabatt"
        @click="showNewDiscountItem = true"
        :disable="
          loadingCount > 0 ||
          showNewDiscountItem ||
          !canEditInquiryPositions ||
          notYetAddedProductGroups.length === 0
        "
      />
    </div>
  </template>
  <template v-else>
    {{ $t("productGroupDiscount.noDiscounts") }}
  </template>
</template>

<script setup lang="ts">
import { listProductGroups } from "@/api/productGroup";
import * as api from "@/api/productGroupDiscount";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import type {
  NoProductGroup,
  ProductGroup,
  ProductGroupOrNoGroup,
} from "@/types/productGroup";
import type {
  NoProductGroupDiscount,
  ProductGroupDiscount,
} from "@/types/productGroupDiscount";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import InquiryDiscountItem from "./InquiryDiscountItem.vue";
import NewInquiryDiscountItem from "./NewInquiryDiscountItem.vue";

const { inquiryId } = useRouteParams();
const inquiryStore = useCurrentInquiryStore();
const { inquiry, canEditInquiryPositions } = storeToRefs(inquiryStore);

const discounts = ref<ProductGroupDiscount[]>([]);
const productGroups = ref<ProductGroup[]>([]);
const loadingCount = ref(0);
const isAddingDiscount = ref(false);
const isDeletingDiscountById = ref<Record<number, boolean>>({});
const isUpdatingDiscountById = ref<Record<number, boolean>>({});

const hasDiscounts = computed(
  () => discounts.value.length > 0 || noProductGroupDiscount.value !== null
);
const showNewDiscountItem = ref(false);

const { organizationId } = useRouteParams();

async function loadProductGroups() {
  loadingCount.value++;
  try {
    productGroups.value = await listProductGroups(organizationId.value);
  } finally {
    loadingCount.value--;
  }
}

onMounted(loadProductGroups);
watch(organizationId, loadProductGroups);

const notYetAddedProductGroups = computed(() => {
  const addedGroupIds = discounts.value.map(
    (discount) => discount.productGroup.id
  );

  const result = productGroups.value.filter(
    (productGroup) => !addedGroupIds.includes(productGroup.id)
  ) as ProductGroupOrNoGroup[];

  if (inquiry.value?.discountRateProductsWithoutGroup === null) {
    result.push(noProductGroup);
  }

  return result;
});

const { t } = useI18n();

const noProductGroup = {
  id: null,
  name: t("productGroup.noGroup"),
} as NoProductGroup;

const noProductGroupDiscount = computed<NoProductGroupDiscount | null>(() => {
  const discountRateProductsWithoutGroup =
    inquiry.value?.discountRateProductsWithoutGroup;
  if (discountRateProductsWithoutGroup == null) {
    return null;
  }
  return {
    id: null,
    productGroup: noProductGroup,
    discountRate: discountRateProductsWithoutGroup,
  };
});

async function deleteDiscount(discountId: number | null) {
  isDeletingDiscountById.value[discountId ?? 0] = true;
  try {
    if (discountId === null) {
      await updateNoProductGroupDiscount(null);
    } else {
      await api.deleteProductGroupDiscount(inquiryId.value, discountId);
      discounts.value = discounts.value.filter(
        (discount) => discount.id !== discountId
      );
    }
  } finally {
    isDeletingDiscountById.value[discountId ?? 0] = false;
  }
}

async function updateDiscount(discountId: number | null, discountVal: number) {
  isUpdatingDiscountById.value[discountId ?? 0] = true;
  try {
    if (discountId === null) {
      await updateNoProductGroupDiscount(discountVal);
    } else {
      await api.updateProductGroupDiscount(inquiryId.value, discountId, {
        discountRate: discountVal,
      });
      discounts.value = discounts.value.map((discountObj) => {
        if (discountObj.id === discountId) {
          return { ...discountObj, discountRate: discountVal };
        }
        return discountObj;
      });
    }
  } finally {
    isUpdatingDiscountById.value[discountId ?? 0] = false;
  }
}

async function addDiscount(
  productGroup: ProductGroupOrNoGroup,
  discount: number
) {
  isAddingDiscount.value = true;

  try {
    if (productGroup.id === null) {
      await updateNoProductGroupDiscount(discount);
    } else {
      discounts.value.push(
        await api.createProductGroupDiscount(inquiryId.value, productGroup.id, {
          discountRate: discount,
        })
      );
    }
  } finally {
    isAddingDiscount.value = false;
    showNewDiscountItem.value = false;
  }
}

async function updateNoProductGroupDiscount(value: number | null) {
  await inquiryStore.updateInquiry({
    discountRateProductsWithoutGroup: value,
  });
}

async function loadDiscounts() {
  loadingCount.value++;
  try {
    discounts.value = await api.listProductGroupDiscounts(inquiryId.value);
  } finally {
    loadingCount.value--;
  }
}

onMounted(loadDiscounts);
watch(inquiryId, loadDiscounts);
</script>

<style scoped lang="scss">
.discount-input {
  width: 100px;
}
</style>
