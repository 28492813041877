<template>
  <div class="column no-wrap items-start">
    <date-display
      :date="quotation.createdAt"
      :icon="icons.quotation.createdDate"
      :show-overdue-close="false"
      :name="$t('quotation.createdAt')"
    />
    <div
      class="row no-wrap items-center gap-xs full-width rounded-borders"
      :class="{ 'show-edit-on-hover': isEditable }"
      v-catch-clicks="isEditable ? () => {} : null"
    >
      <date-display
        class="col"
        :date="quotation.acceptedOrDeclinedAt"
        :icon="
          quotation.status === 'DECLINED'
            ? icons.quotation.statusOptions.DECLINED
            : icons.quotation.statusOptions.ACCEPTED
        "
        :name="
          quotation.status === 'DECLINED'
            ? $t('quotation.declinedAt')
            : $t('quotation.acceptedAt')
        "
        :show-overdue-close="false"
      />
      <q-icon name="sym_r_edit" class="edit-icon" color="neutral-7" />
      <q-menu v-if="isEditable" class="q-pa-sm">
        <date-input
          dense
          borderless
          autofocus
          :model-value="quotation.acceptedOrDeclinedAt"
          @update:model-value="updateAcceptedOrDeclinedAt"
        />
      </q-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import DateDisplay from "@/components/DateDisplay.vue";
import DateInput from "@/components/DateInput.vue";
import icons from "@/config/icons";
import { useCurrentQuotationsStore } from "@/stores/currentQuotations";
import type { Quotation } from "@/types/quotation";
import { computed } from "vue";

const props = defineProps<{
  quotation: Quotation;
}>();

const isEditable = computed(() => {
  return props.quotation.status !== "CREATED";
});

const store = useCurrentQuotationsStore();

const updateAcceptedOrDeclinedAt = (date: Date | null | undefined) => {
  if (date === undefined) return;
  store.updateQuotation(props.quotation.id, {
    acceptedOrDeclinedAt: date,
  });
};
</script>

<style scoped lang="scss">
.show-edit-on-hover {
  cursor: pointer;

  &:hover {
    background-color: $neutral-2;
    .edit-icon {
      display: block;
    }
  }
}

.edit-icon {
  display: none;
}
</style>
