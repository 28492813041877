<template>
  <header-bar-right>
    <template #right>
      <div class="row no-wrap gap-x-xs">
        <q-btn
          flat
          dense
          color="neutral-7"
          icon="sym_r_list_alt"
          :to="{ name: 'inquiry-positions' }"
          class="no-decoration"
          size="md"
        >
          <q-tooltip>
            {{ $t("inquiryPage.progress.edit") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="organization?.useSupplierData"
          flat
          dense
          :icon="icons.supplierRfq"
          color="neutral-7"
          :to="{ name: 'inquiry-supplier-rfqs' }"
          class="no-decoration"
          size="md"
        >
          <q-tooltip>
            {{ $t("inquiryPage.toSupplierRfqs") }}
          </q-tooltip>
        </q-btn>
      </div>
    </template>
  </header-bar-right>
  <div class="page-below-header-bar bg-neutral-1 q-pa-md column gap-md no-wrap">
    <inquiry-values-display />
    <quotation-values-display />
    <q-separator />
    <inquiry-history />
  </div>
</template>

<script setup lang="ts">
import HeaderBarRight from "@/components/InquiryHeaderBarRight.vue";
import InquiryHistory from "@/components/InquiryPage/InquiryHistory/InquiryHistory.vue";
import InquiryValuesDisplay from "@/components/InquiryPage/InquiryValuesDisplay/InquiryValuesDisplay.vue";
import QuotationValuesDisplay from "@/components/InquiryPage/QuotationValuesDisplay/QuotationValuesDisplay.vue";
import icons from "@/config/icons";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";

const { organization } = storeToRefs(useCurrentOrganizationStore());
</script>
