import type { QInput } from "quasar";

const selectOnFocus = ($event: FocusEvent) => {
  ($event.target as HTMLInputElement).select();
};

const inputAutoselectDirective = {
  mounted(el: typeof QInput) {
    const input = el.querySelector("input");
    input?.addEventListener("focus", selectOnFocus);
  },
  unmounted(el: typeof QInput) {
    const input = el.querySelector("input");
    input?.removeEventListener("focus", selectOnFocus);
  },
};

export default inputAutoselectDirective;
