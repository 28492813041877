import type { QuotationStatus } from "@/types/quotation";
import type { SupplierRFQStatus } from "@/types/supplierRfq";

export const quotationStatusColors: Record<QuotationStatus, string> = {
  ACCEPTED: "positive",
  DECLINED: "negative",
  CREATED: "neutral-7",
};

export const supplierRfqStatusColors: Record<SupplierRFQStatus, string> = {
  DRAFT: "neutral-5",
  SENT: "neutral-7",
  ANSWERED: "positive",
};
