<template>
  <div class="rounded-borders q-pa-md column gap-y-sm" :style="cardStyle">
    <div class="row items-center justify-between">
      <div class="row gap-x-sm items-center" :style="headerStyle">
        <q-icon :name="props.icon" size="24px" />
        <div class="text-base">{{ props.title }}</div>
      </div>
      <slot name="action"></slot>
    </div>
    <div>
      <slot>
        {{ props.text }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  icon: string;
  color:
    | "primary"
    | "secondary"
    | "accent"
    | "positive"
    | "negative"
    | "warning"
    | "neutral";
  title: string;
  text?: string;
}>();

const cardStyle = computed(() => ({
  backgroundColor: `var(--q-${props.color}-1)`,
  color: `var(--q-${props.color}-10)`,
  border: `1px solid var(--q-${props.color}-8)`,
}));

const headerStyle = computed(() => ({
  color: `var(--q-color-${props.color}-8)`,
}));
</script>
